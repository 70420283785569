<template>
  <div>
    <div class="m-2"></div>

    <!-- Staff Documents -->
    <b-card title="Documents">
      <b-table
        id="staffDocumentsTableId"
        ref="refStaffDocumentsTable"
        class="position-relative"
        :items="staffDocsList"
        responsive
        :fields="staffDocumentsColumns"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        primary-key="sd_id"
      >
        <template #cell(index)="data">
          {{ staffDocsList.indexOf(data.item) + 1 }}
        </template>

        <template #cell(doc_name)="data">
          {{
            data.item.doc_name +
            (data.item.extra_text ? " - " + data.item.extra_text : "")
          }}
        </template>

        <template #cell(download)="data">
          <b-button
            size="30"
            role="button"
            @click="downloadDoc(data.item.doc_url)"
          >
            Download
            <!-- <feather-icon icon="Download" size="15" class="text-success" /> -->
          </b-button>
        </template>

        <template #cell(open)="data">
          <b-button
            variant="light-success"
            size="30"
            role="button"
            @click="openDoc(data.item.doc_url)"
          >
            <feather-icon icon="EyeIcon" size="15" class="text-success" />
          </b-button>
        </template>

        <template #cell(uploaded_by)="data">
          {{ data.item.user_firstname + " " + data.item.user_lastname }}
        </template>
      </b-table>
    </b-card>

    <!-- Staff Visa Documents -->
    <b-card title="Visa Documents">
      <b-table
        id="staffVisaDocumentsTableId"
        ref="refStaffVisaDocumentsTable"
        class="position-relative"
        :items="staffVisaDocsList"
        responsive
        :fields="staffVisaDocumentsColumns"
        :per-page="pagination_visa.perPage"
        :current-page="pagination_visa.currentPage"
        primary-key="sd_id"
      >
        <template #cell(index)="data">
          {{ staffVisaDocsList.indexOf(data.item) + 1 }}
        </template>

        <template #cell(vdoc_name)="data">
          {{
            data.item.vdoc_name +
            (data.item.extra_text ? " - " + data.item.extra_text : "")
          }}
        </template>

        <template #cell(download)="data">
          <b-button
            size="30"
            role="button"
            @click="downloadDoc(data.item.vdoc_url)"
          >
            Download
          </b-button>
        </template>

        <template #cell(open)="data">
          <b-button
            variant="light-success"
            size="30"
            role="button"
            @click="openDoc(data.item.vdoc_url)"
          >
            <feather-icon icon="EyeIcon" size="15" class="text-success" />
          </b-button>
        </template>

        <template #cell(uploaded_by)="data">
          {{ data.item.user_firstname + " " + data.item.user_lastname }}
        </template>
      </b-table>
    </b-card>
    
  </div>
</template>

<script>
import {
  //   BAvatar,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config/index";
import store from "@/store";
import StudentServices from "@/apiServices/StudentServices";
import axios from "axios";
import helpers from "@/libs/helpers";

export default {
  components: {
    // BAvatar,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      student_user_id: null,
      staffDocsList: [],
      staffVisaDocsList: [],
      //   fullStaffDocsList: [],
      staffDocumentsColumns: [
        { key: "index", label: "#" },
        { key: "doc_name", label: "Type of document" },
        // { key: "university_name", label: "University Name" },
        // { key: "download", label: "Download" },
        { key: "uploaded_by", label: "Uploaded By" },
        {
          key: "created_at",
          label: "Uploaded Date",
          formatter: helpers.getDateTimeFormatted,
        },
        { key: "open", label: "Preview" },
      ],
      staffVisaDocumentsColumns: [
        { key: "index", label: "#" },
        { key: "vdoc_name", label: "Type of Visa document" },
        // { key: "university_name", label: "University Name" },
        // { key: "download", label: "Download" },
        { key: "uploaded_by", label: "Uploaded By" },
        {
          key: "created_at",
          label: "Uploaded Date",
          formatter: helpers.getDateTimeFormatted,
        },
        { key: "open", label: "Preview" },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      pagination_visa: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      FILESURL,
    };
  },
  methods: {
    openDoc(doc_url) {
      const url = FILESURL + doc_url;
      window.open(url);
    },
    downloadDoc(doc_url) {
      let url = `${FILESURL}${doc_url}`;
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = doc_url.split("/")[doc_url.split("/").length - 1];
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    async getStaffDocuments() {
      try {
        const response = await StudentServices.getStaffDocuments();
        if (response.data.status) {
          this.staffDocsList = response.data.data;

          //   this.fullStaffDocsList = response.data.data;
        }
      } catch (error) {
        console.log("Error in getting staff Documents ", error);
      }
    },
    async getStaffVisaDocuments() {
      try {
        const response = await StudentServices.getStaffVisaDocuments();
        if (response.data.status) {
          this.staffVisaDocsList = response.data.data;
        }
      } catch (error) {
        console.log("Error in getting staff Documents ", error);
      }
    },
  },
  beforeMount() {
    this.getStaffDocuments();
    this.getStaffVisaDocuments();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
