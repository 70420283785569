<template>
  <div>
    <div v-if="!checklist">
      <b-card>
        <p>
          This section will be available when you get accepted in university.
        </p>
      </b-card>
    </div>
    <div v-else>
      <b-card>
        <div style="margin-inline: 14px">
          <div
            class="mb-2"
            style="
              padding: 2% 2% 2% 2%;
              border-color: #000;
              border-width: 1px;
              border-style: solid;
            "
          >
            <h3 style="color: #000">{{ checklist.vc_name }}</h3>
          </div>

          <div class="mb-2">
            <!-- <p class="font-small-3 mb-0" style="margin-bottom: 8px">
                Details:
              </p> -->

            <div
              id="to-print"
              style="
                padding: 2% 2% 2% 2%;
                border-color: #000;
                border-width: 1px;
                border-style: solid;
              "
              class="font-weight-bolder"
              v-html="checklist.vc_text"
            ></div>
          </div>
        </div>
      </b-card>
      <b-card>
        <app-collapse>
          <app-collapse-item title="Sample of Required Documents:">
            <b-row>
              <b-col
                v-for="(doc, docIndex) in checklist.vc_docs"
                :key="'doc_' + docIndex"
                cols="12"
                md="3"
              >
                <span>{{ doc.vcd_name }}</span>
                <br />
                <embed
                  :src="$FILESURL + doc.vcd_doc"
                  width="200px"
                  height="300px"
                />
                <br />
                <span>
                  <a :href="$FILESURL + doc.vcd_doc">open</a>
                </span>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCol, BRow } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import StudentServices from "@/apiServices/StudentServices";
import html2pdf from "html2pdf.js";

export default {
  components: { BCard, AppCollapse, AppCollapseItem, BCol, BRow },
  data() {
    return {
      checklist: false,
    };
  },
  methods: {
    downloadPDF(id, title) {
      html2pdf(document.getElementById(id), {
        margin: 5,
        filename: title + ".pdf",
      });
    },
    async getVisaChecklist() {
      try {
        const response = await StudentServices.visaChecklist();
        if (response.data.status) {
          this.checklist = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting ", err);
      }
    },
  },
  beforeMount() {
    this.getVisaChecklist();
  },
};
</script>

<style></style>
